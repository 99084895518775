import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// components
import Page from "../../components/Page";
import { GetVaApiClient } from "../../utils/HttpClient";
import { ProductionRigTest } from "../../models/ProductionRigTest";
import { ProductionRigTestCard } from "../../components/ProductionRigTests/ProductionRigTestCard";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";

const ProductionTestPage: React.FC = () => {
  const { serialNumber } = useParams();
  const [productionRigTests, setProductionRigTests] = useState<
    ReadonlyArray<ProductionRigTest>
  >([]);

  useEffect(() => {
    if (serialNumber) {
      const axiosClient = GetVaApiClient();
      axiosClient
        .get<ReadonlyArray<ProductionRigTest>>(
          `production-rig-tests/${serialNumber}`
        )
        .then((tests) => {
          let sortedTests = [...tests.data].sort(
            (a, b) =>
              new Date(b.Date ?? 0).getTime() - new Date(a.Date ?? 0).getTime()
          );

          let groupedTests = new Map<number, ProductionRigTest[]>();
          for (let test of sortedTests) {
            groupedTests.set(test.ChannelPosition ?? -1, [
              ...(groupedTests.get(test.ChannelPosition ?? -1) ?? []),
              test,
            ]);
          }
          let sortedGroups = Array.from(groupedTests.entries()).sort(
            (a, b) => a[0] - b[0]
          );

          let firstOfEachChannel: ProductionRigTest[] = [];
          for (let group of sortedGroups) {
            firstOfEachChannel.push(group[1][0]);
          }
          if (tests.data.length > 0) {
            setProductionRigTests(firstOfEachChannel);
          }
        });
    }
  }, [serialNumber]);

  return (
    <Page title="Production test">
      <Container style={{marginTop: 50}}>
        <Stack direction="column" spacing={2}>
          <Stack direction="row">
            <Box component={Link} to="/" sx={{ display: "inline-flex" }}>
              <Box
                component="img"
                src="/static/piab-logga.jpg"
                sx={{ width: 40, height: 40 }}
                style={{marginRight: 50}}
              />
            </Box>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Test results for {serialNumber}
            </Typography>
          </Stack>
          {productionRigTests.map((value) => (
            <ProductionRigTestCard key={value.Id} productionTest={value} />
          ))}
        </Stack>
      </Container>
    </Page>
  );
};
export default ProductionTestPage;
