import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { ProductionRigTest } from "../../models/ProductionRigTest";
import { Parameter } from "./Parameter";

export const ProductionRigTestCard: React.FC<{
  productionTest: ProductionRigTest;
}> = ({ productionTest }) => {
  return (
    <Card>
      <CardHeader />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Stack direction={"column"} spacing={2}>
              <TextField
                fullWidth
                label="Performed"
                value={`${new Date(
                  productionTest?.Date ?? new Date()
                ).toLocaleDateString()} - ${new Date(
                  productionTest?.Date ?? new Date()
                ).toLocaleTimeString()}`}
              />
              <TextField
                fullWidth
                type={"number"}
                label="Channel"
                value={productionTest?.ChannelPosition ?? ""}
              />
              <TextField
                fullWidth
                label="Location"
                value={productionTest?.Location ?? ""}
              />
            </Stack>
          </Grid>
            <Grid item xs={6}>
                <Stack direction={"column"} spacing={2}>
                    <TextField
                        fullWidth
                        label="Product flavor"
                        value={productionTest.ProductFlavor ?? ""}
                    />
                    <TextField
                        fullWidth
                        label="Air connection type"
                        value={productionTest?.AirConnectionType ?? ""}
                    />
                </Stack>
            </Grid>
          <Grid item xs={12}>
            <Parameter
              parameters={productionTest.ProductionRigTestParameters}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
