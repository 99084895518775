import React from "react";
import { ProductionRigTestParameter } from "../../models/ProductionRigTestParameter";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export const Parameter: React.FC<{
  parameters: ReadonlyArray<ProductionRigTestParameter>;
}> = ({ parameters }) => {
  let sortedParameters = parameters as Array<ProductionRigTestParameter>;
  sortedParameters.sort((a, b) => {
    if (a.Name && b.Name && a.Name < b.Name) {
      return -1;
    }
    if (a.Name && b.Name && a.Name > b.Name) {
      return 1;
    }
    return 0;
  });
  return (
    <Card>
      <CardContent>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Test Name</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Min</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Max</TableCell>
              <TableCell>Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedParameters.map((parameter) => (
              <TableRow
                key={parameter.Id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {parameter.Name}
                </TableCell>
                <TableCell>
                  <Typography>{parameter.Description}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{parameter.ValueUnit}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{parameter.LowerLimit}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {Math.round(
                      (parameter.MeasuredValue ?? Number.EPSILON) * 100
                    ) / 100}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{parameter.UpperLimit}</Typography>
                </TableCell>
                <TableCell>
                  {parameter.TestPassed ? (
                    <Typography color={"green"}>Passed</Typography>
                  ) : (
                    <Typography color={"red"}>Failed</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
