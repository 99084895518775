import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
// material
import { Box } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { BoxProps, BoxTypeMap } from "@mui/material/Box/Box";

// ----------------------------------------------------------------------

const Page = forwardRef<OverridableComponent<BoxTypeMap>, BoxProps>(
  ({ children, title = "", ...other }, ref) => (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  )
);

Page.propTypes = {};

export default Page;
