import { Navigate, useRoutes } from "react-router-dom";

import NotFound from "./pages/Page404";
import ProductionTestPage from "./pages/ProductionTests/ProductionTestPage";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/tests/:serialNumber",
      element: <ProductionTestPage />,
    },
    { path: "404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
